<template>
  <div id="app">
    <not-found-content></not-found-content>
  </div>
</template>

<script>
import NotFoundContent from '@/components/NotFoundContent.vue'

export default {
  name: 'App',
  components: {
    NotFoundContent,
  }
}

</script>

<style scoped>
body {
    padding: 0;
    margin: 0;
}

#app {
    padding: 0;
    margin: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
