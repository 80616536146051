<template>
    <div class="about-shawl">
        <h1>Momento do Xale...</h1>
        <div class="container mb-4 pb-4">
            <div class="row m-4">
                <div class="col-6 text-box text-left">
                    <span>A</span>ncestralidade dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
                <div class="col-6 img-box d-flex justify-content-around">
                    <img src="../assets/img/shawl-icon1-g.png" alt="shawl icon">
                </div>
            </div>
            <div class="row m-4 py-4">
                <div class="col-6 img-box d-flex justify-content-around">
                    <img src="../assets/img/shawl-icon2-g.png" alt="shawl icon">
                </div>
                <div class="col-6 text-box text-right">
                    <span>P</span>roteção dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
            </div>
            <div class="row m-4 py-4">
                <div class="col-6 text-box text-left">
                    <span>A</span>utoconhecimento dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
                <div class="col-6 img-box d-flex justify-content-around">
                    <img src="../assets/img/shawl-icon3-g.png" alt="shawl icon">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.about-shawl {
    min-height: 100vh; /* TODO: remove */
    background-color: #ECB865;
    padding: 1.5em;
    line-height: 1.7;
}

h1 {
    text-align: center;
    font-weight: 400;
    font-size: 4.4em;
    padding: 1.2em 0;
    color: rgb(27, 70, 73);
    color: #1b4649;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}

.text-box {
    font-size: 1.4em;
    color: #1b4649;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}

.text-left {
    padding-right: 1.5em;
}

.text-right {
    padding-left: 1.5em;
    text-align: right;
}

.text-box span {
    font-size: 1.6em;
    padding-right: .05em;
}

.img-box {
    height: 215px;
}

.img-box img {
    display: block;
    margin: auto;
    max-height: 80%;
    max-width: 80%;
    padding: 10px;
}
    
</style>