<template>
    <div id="app">
        <nav-bar></nav-bar>
        <about-shawl></about-shawl>
        <shawl-moment></shawl-moment>
        <footer-component></footer-component>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import AboutShawl from '@/components/AboutShawl.vue'
import ShawlMoment from '@/components/ShawlMoment.vue'
import FooterComponent from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    AboutShawl,
    ShawlMoment,
    FooterComponent
  }
}

</script>

<style scoped>
body {
    padding: 0;
    margin: 0;
}
</style>
