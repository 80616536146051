<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

export default {}

</script>

<style>
body {
    padding: 0;
    margin: 0;
}

#app {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}
</style>
