<template>
    <div>
        <div class="d-flex px-4 pt-3 pb-2 bd-highlight prenav-item prenav-bg">
            <div class="prenav-item pt-1">
                <span>
                    <a href="https://www.instagram.com/erikapimenta/" class="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ECB865" class="bi bi-instagram mx-1" viewBox="0 0 16 16">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                        </svg>
                    </a>
                    <a href="https://www.facebook.com/erikapimenta-107837231379379" class="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ECB865" class="bi bi-facebook mx-1" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg>
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=5512996681196" class="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ECB865" class="bi bi-whatsapp mx-1" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                        </svg>
                    </a>
                </span>
            </div>
            <div class="prenav-item prenav-logo">
                <img src="@/assets/img/logo/coruja3.png" alt="Logo coruja">
            </div>
        
            <div class="prenav-item">
                <!-- <span>Login | Cadastre-se</span> --> <!-- Ativo quando o site possuir sistema interno -->
            </div>
        </div>
        <nav class="navbar navbar-expand-md navbar-light nav-bg">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse text-center" id="navbarNavAltMarkup">
                    <div class="navbar-nav mx-md-auto">
                        <a class="nav-link nav-item-active px-4" aria-current="page" href="#">Página inicial</a>
                        <a class="nav-link nav-item px-4" href="#">Vitrine</a>
                        <a class="nav-link nav-item px-4" href="#">Nossa História</a>
                        <a class="nav-link nav-item px-4" href="#">Modelos</a>
                        <a class="nav-link nav-item px-4" href="#">Contato</a>
                    </div>
                </div>
            </div>
        </nav>
        <div class="container-fluid landing-body">
            <div class="welcome-card row">
                <div class="col-lg-8 col-md-12 align-self-center">
                    <div class="main-text mb-4">
                        O xale abraça<br> sua história!
                    </div>
                    <div class="sub-text">
                        Escolha o seu abraço!
                    </div>
                    <div class="arrow-down" @click="scrollToNext()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        scrollToNext: function () {
            // this.$scrollTo('#archor', 500, this.options);
        },
        loopThroughImages: function() {
            let i = 1;

            setTimeout(function() {
                i++;
                if (i < 10) {
                    this.loopThroughImages();
                }
            }, 5000)
        }
    },
}
</script>

<style scoped>

    /* Navbar */
    .prenav-bg {
        background-color: #1b4649;
    }
    .prenav-item {
        flex: 1;
        display: flex;
        justify-content: center;
        overflow-x: 10;
    }
    .prenav-item:first-child > span { 
        margin-right: auto;
    }
    .prenav-item:last-child  > span { 
        margin-left: auto;
    }
    .prenav-logo img {
        max-height: 85px;
        height: auto;
        margin-top: -7px;
        margin-bottom: 7px;
    }
    .navbar {
        box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.35);
        overflow-x: 100;
    }
    .nav-bg {
        background-color: #1b4649;
    }
    .nav-item:link {
        color: #DFDFDF;
    }
    .nav-item-active:link {
        color: #ECB865;
        font-weight: 600;
    }

    /* Landing page body */
    .landing-body {
        height: calc(100vh - 165px);
        background: url('../assets/img/bg/bg1.jpg') no-repeat center;
        background-position: right top;
    }
    .welcome-card {
        color: white;
        text-align: center;
        height: 90%;
    }
    .welcome-card .main-text {
        font-size: 4.4em;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, .5);
        font-weight: 500;
    }
    .welcome-card .sub-text {
        font-size: 1.8em;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, .5);
        cursor: pointer;

    }
    .welcome-card .arrow-down {
        margin-top: -15px;
        filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .5));
        cursor: pointer;
    }

</style>