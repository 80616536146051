<template>
  <div id="app">
    <coming-soon></coming-soon>
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar.vue'
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  name: 'App',
  components: {
    ComingSoon,
    // NavBar
  }
}

</script>

<style scoped>
body {
    padding: 0;
    margin: 0;
}

#app {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
