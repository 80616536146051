<template>
    <div class="about-shawl m-0 p-0">
        <div class="header">
            <h1>História do Xale...</h1>
        </div>
        <div class="container-fluid m-0 p-0">
            <div class="row m-0 p-0">
                <div class="col-6 text-container d-block m-auto md-4 px-4">
                    <hr class="golden-flourish">
                    <div class="text-box text-center px-4 my-4">
                        <p><span>P</span>roteção dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <hr class="golden-flourish-2">
                </div>
                <div class="col-6 img-box img-1"></div>
            </div>
            <div class="row m-0 p-0">
                <div class="col-6 img-box img-2"></div>
                <div class="col-6 text-container d-block m-auto px-4">
                    <hr class="golden-flourish">
                    <div class="text-box text-center px-4 my-4">
                        <p><span>A</span>ncestralidade dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <hr class="golden-flourish-2">
                </div>
            </div>
            <div class="row m-0 p-0">
                <div class="col-6 text-container d-block m-auto px-4 text-left">
                    <hr class="golden-flourish">
                    <div class="text-box text-center px-4 my-4">
                        <p><span>A</span>utoconhecimento dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <hr class="golden-flourish-2">
                </div>
                <div class="col-6 img-box img-3"></div>
            </div>
            <div class="row m-0 p-0">
                <div class="col-6 img-box img-4"></div>
                <div class="col-6 text-container d-block m-auto px-4">
                    <hr class="golden-flourish">
                    <div class="text-box text-center px-4 my-4">
                        <p><span>C</span>onforto dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <hr class="golden-flourish-2">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        cssImage: (n) => {
            return { background: `url(${require('@/assets/img/about/about' + n + '.jpg')}}) no-repeat center;` } 
        }
    },
}
</script>

<style scoped>
.about-shawl {
    background-color: #1b4649;
    padding: 1.5em;
    line-height: 1.7;
}

.header {
    box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.35);
}

h1 {
    text-align: center;
    font-weight: 400;
    font-size: 4.4em;
    padding: 1.2em 0;
    margin: 0;
    color: rgb(27, 70, 73);
    color: #ECB865;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .4));
}

.text-container {
    font-size: 1.4em;
    color: #ECB865;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, .4));
}

hr.golden-flourish {
    border: 0;
    margin: auto;
    height: 100px;
    width: 80%;
    background: url('../assets/img/flourish-golden.png') no-repeat center;
    opacity: .7;

}

hr.golden-flourish-2 {
    border: 0;
    margin: auto;
    height: 100px;
    width: 80%;
    background: url('../assets/img/flourish-golden-2.png') no-repeat center;
    opacity: .7;
}

.text-box span {
    font-size: 1.6em;
    padding-right: .05em;
}

.img-box {
    position: relative;
    box-shadow: inset 0px 0px 37px -1px rgba(0,0,0,0.75) ;
    -webkit-box-shadow: inset 0px 0px 37px -1px rgba(0,0,0,0.75) ;
    -moz-box-shadow: inset 0px 0px 37px -1px rgba(0,0,0,0.75);
    width: 49.558vw;
    height: 58vh;
}

.img-1 {
    background: url('../assets/img/about/about1.jpg') no-repeat center;
}

.img-2 {
    background: url('../assets/img/about/about2.jpg') no-repeat center;
}

.img-3 {
    background: url('../assets/img/about/about3.jpg') no-repeat center;
}

.img-4 {
    background: url('../assets/img/about/about4.jpg') no-repeat center;
}
    
</style>