import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import Home from '@/views/Home.vue'
import TrueHome from '@/views/TrueHome.vue'
import NotFound from '@/views/NotFound.vue'

const DEFAULT_TITLE = "Erika Pimenta"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
        title: 'Em breve...'
    }
  },
  {
    path: '/dev/',
    name: 'TrueHome',
    component: TrueHome,
    meta: {
        title: 'Home'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
        title: 'Página não encontrada.'
    }
},
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = (to.meta.title + ' | Erika Pimenta') || DEFAULT_TITLE;
    });
});

export default router